<template>
  <div
    style="background-color:var(--color-beige); color:var(--color-braun);"
  >
    <div class="bg bg-repeat-x h-10 lg:h-16" />
    <div class="max-w-5xl mx-auto px-5 lg:px-10 py-10 lg:py-32 text-center tracking-widest leading-8">
      <div class="uppercase">
        lust auf
      </div>
      <div class="text-5xl md:text-8xl font-bold mb-10 font-['Boska']">
        Kaffeegenuss?
      </div>
      <div class="uppercase mb-20">
        Frisch gemahlener Kaffee, hausgemachte Torten oder cremiges Eis aus der Region - im Genuss Café findet man alles, was das Herz begehrt. Direkt am Mozartplatz in St. Gilgen, umgeben von Kirchturm und historischen Gebäuden, bleibt die Zeit für einen Moment lang stehen, um unsere hausgemachten Spezialitäten in vollen Zügen zu genießen. Willkommen im Genuss Café!
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
          <img
            class="w-full mb-4"
            src="@/assets/Bilder/Genuss-Cafe-Fruehstueck-QV.jpg"
          >
          <img
            class="w-full"
            src="@/assets/Bilder/Genuss-Cafe-Pralinen-HV.jpg"
          >
        </div>
        <div>
          <img
            class="w-full mt-8 mb-4"
            src="@/assets/Bilder/Genuss-Cafe-Sachertorte-HV.jpg"
          >
          <img
            class="w-full my-4"
            src="@/assets/Bilder/Genuss-Cafe-Kaffemuehle-QV.jpg"
          >
          <img
            class="w-full my-4"
            src="@/assets/Bilder/Genuss-Cafe-Mozart-Stueberl-HV.jpg"
          >
          <img
            class="w-full my-4"
            src="@/assets/Bilder/Genuss-Cafe-Torten-QV.jpg"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Story'
}
</script>

<style scoped lang="scss">
.bg {
  background-image: url('~@/assets/Grafiken/Genuss-Cafe-Muster.svg');
}
</style>
